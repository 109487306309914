const faqSection = document.querySelector('.faq-section');
console.log('Hello')
if (faqSection) {
  const faqItemsArr = document.querySelectorAll('.faq-section__item');

  // Відкрити перший аккордеон за замовчуванням
  const firstItem = faqItemsArr[0];
  const firstIcon = firstItem.querySelector('.faq-section__item-button i');
  const firstContent = firstItem.querySelector('.faq-section__item-bottom-content');
  const firstHiddenEl = firstItem.querySelector('.faq-section__item-bottom');
  const firstContentHeight = firstContent.clientHeight;

  firstItem.classList.add('active');
  firstIcon.classList.remove('icon-plus');
  firstIcon.classList.add('icon-minus');
  firstHiddenEl.style.maxHeight = `${firstContentHeight + 30}px`;

  faqItemsArr.forEach((item) => {
    item.addEventListener('click', () => {
      const icon = item.querySelector('.faq-section__item-button i');
      const content = item.querySelector('.faq-section__item-bottom-content');
      const hiddenEl = item.querySelector('.faq-section__item-bottom');
      const contHeight = content.clientHeight;

      if (item.classList.contains('active')) {
        item.classList.remove('active');
        icon.classList.add('icon-plus');
        icon.classList.remove('icon-minus');
        hiddenEl.style.maxHeight = null; // Закрити поточний елемент
      } else {
        openItem();
      }

      function openItem() {
        for (let i = 0; i < faqItemsArr.length; i++) {
          const iconEl = faqItemsArr[i].querySelector('.faq-section__item-button i');
          const hiddenEl = faqItemsArr[i].querySelector('.faq-section__item-bottom');
          faqItemsArr[i].classList.remove('active');
          iconEl.classList.add('icon-plus');
          iconEl.classList.remove('icon-minus');
          hiddenEl.style.maxHeight = null; // Закрити всі елементи
        }

        item.classList.add('active');
        icon.classList.remove('icon-plus');
        icon.classList.add('icon-minus');
        hiddenEl.style.maxHeight = `${contHeight + 30}px`; // Відкрити поточний елемент
      }
    });
  });
}
